<template>
  <auth>
    <template slotscope="defaultSlotScope">
      <a-row type="flex" justify="center" class="full_height" align="middle">
        <a-alert
          v-if="!spin && !success"
          type="error"
          message="Erreur dans la confirmation veuillez contacter votre administrateur"
          banner
        />
        <a-alert
          v-if="success && !spin"
          type="success"
          message=" Votre inscription a été effectuée avec succès, veuillez vérifier votre mail pour confirmation"
          banner
        />

        <a-col class="height-100">
          <div class="example h-100" v-if="spin">
            <a-spin size="large" tip="Confirmation..." :spinning="spin" />
          </div>
        </a-col>
      </a-row>
    </template>
  </auth>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import Auth from "@/components/common/AuthParent";
const PROFILE_ROUTE_NAME = 'adherent_profil';

export default {
  name: "ConfirmMail",
  components: {
    Auth,
  },
  created() {
    this.logout();
  },
  mounted() {
    this.confirmRegistration(this.$route.params.br)
      .then(() => {
        this.spin = false;
        this.success = true;
        this.$router.push({ name: PROFILE_ROUTE_NAME });
      })
      .catch(() => {
        this.spin = false;
      });
  },
  data() {
    return {
      success: false,
      spin: true,
    };
  },
  methods: {
    ...mapActions(["confirmRegistration"]),
    ...mapMutations({ logout: "clearAuthentication" }),
  },
};
</script>

<style scoped>
.example {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.full_height {
  height: 100%;
}
</style>
